import React from 'react';
import './Button.css';

interface ButtonProps {
  className?: string,
  children: React.ReactNode,
  onClick?: React.MouseEventHandler
}


interface AnchorButtonProps extends ButtonProps {
  href: string
}

export function AnchorButton(props: AnchorButtonProps) {
  return (
    <a className={`button ${props.className}`} href={props.href} onClick={props.onClick}>{props.children}</a>
  );
}

export function Button(props: ButtonProps) {
  return (
    <button className={`button ${props.className}`} type="button"  onClick={props.onClick}>{props.children}</button>
  );
}