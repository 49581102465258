import React from "react";
import { AnchorButton, Button } from '../Button';

import beeid from './logos/beeid.png';
import digid from './logos/digid.png';
import dkmitid from './logos/dkmitid.png';
import dknemid from './logos/dknemid.png';
import ftnmobile from './logos/ftnmobile.png';
import itsme from './logos/itsme.png';
import nobankid from './logos/nobankid.png';
import novipps from './logos/novipps.png';
import sebankid from './logos/sebankid.png';
import sofort from './logos/sofort.png';

import './AuthMethodButton.css';

interface AuthMethodButtonProps {
  acrValue: string,
  href?: string;
  onClick?: React.MouseEventHandler,
  children?: React.ReactNode,
  'data-testid'?: string,
  className?: string
}

export default function AuthMethodButton(props: AuthMethodButtonProps) {
	const {acrValue, href} = props;
	const className = `button-eid ${acrValueToClassName(acrValue)}${props.className ? ` ${props.className}` : ''}`;

	if (href) {
		return (
			<AnchorButton {...props} href={href} className={className}>
				{acrValueToLogo(acrValue) ? <img src={acrValueToLogo(acrValue)} alt="" /> : null}
				{props.children}
			</AnchorButton>
		);
	}

	return (
		<Button {...props} className={className}>
			{acrValueToLogo(acrValue) ? <img src={acrValueToLogo(acrValue)} alt="" /> : null}
			{props.children}
		</Button>
	);
}

function acrValueToClassName(value: string) {
  value = value.replace('urn:grn:authn:', '');
  const segments = value.split(':');
  const classNames = segments.reduce((memo: string[], segment: string) => {
    if (memo.length) {
      return memo.concat([`${memo[memo.length-1]}-${segment}`])
    } else {
      return memo.concat([segment]);
    }
  }, []);

  return classNames.map(className => `button-${className}`).join(' ');
}

function acrValueToLogo(value : string) {
	if (value.startsWith('urn:grn:authn:be:eid')) {
		return beeid;
	}
	if (value.startsWith('urn:grn:authn:nl:digid')) {
		return digid;
	}
	if (value.startsWith('urn:grn:authn:dk:mitid')) {
		return dkmitid;
	}
	if (value.startsWith('urn:grn:authn:dk:nemid')) {
		return dknemid;
	}
	if (value.startsWith('urn:grn:authn:fi')) {
		return ftnmobile;
	}
	if (value.startsWith('urn:grn:authn:itsme')) {
		return itsme;
	}
	if (value.startsWith('urn:grn:authn:se:bankid')) {
		return sebankid;
	}
	if (value.startsWith('urn:grn:authn:de:sofort')) {
		return sofort;
	}
	if (value.startsWith('urn:grn:authn:no:bankid')) {
		return nobankid;
	}
	if (value.startsWith('urn:grn:authn:no:vipps')) {
		return novipps;
	}
}